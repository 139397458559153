.ms-Check {
  visibility: hidden !important;
}
.filterDropdown {
  display: flex;
}

.ms-List-page {
  max-height: 250px;
  overflow: scroll;
}
.beMBck {
  width: 99% !important;
}

.ms-DetailsRow-cell {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.beMBck {
  width: 50vh;
  height: 79vh;
}
