body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker__time-container {
  width: 200px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 200px !important;
}

.react-datepicker__time-box {
  width: 200px !important;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.text-conflict:after {
  content: "Conflict";
  font-size: 10px;
  color: red;
  padding-left: 2px;
}


.upload_image {
  min-width: 150px;
}

.profile_img {
  width: 80px;
  height: 80px;
  padding: 8px;
}

.user_auth {
  margin-top: 5px;
}

.excludeDate {
  display: none;
}

.custom-stripe-card-outer {
  width: 100%;

  border: 1px solid rgb(96, 94, 92);
  border-radius: 2px;
  height: 32px;
  background-color: white;
  position: relative;
  width: calc(100% - 150px);
}
.custom-stripe-card-outer > div{
  padding-top: 7px;
}